
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}


/* Globals */
.fullscreen {
  height: 100%;
  width: 100%;
}

.icon__inline {
  margin-right: 5px;
}

.center {
  text-align: center;
  margin: auto;
  width: 100%;
  height: 100%;
}

.scroll__parent {
  -webkit-overflow-scrolling: auto;
  overflow: auto;
}

.scroll__y_touch {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.ellipsis {
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.pre__line {
  white-space: pre-line;
}

.inline__block {
  display: inline-block;
}

.warning {
  color: red;
}

.has-action {
  cursor: pointer;
}

img.responsive-img, video.responsive-video {
  max-width: 100%;
  height: auto;
}

.x--hidden {
  display: none;
}

.x--visible {
  display: block;
}

.circle {
  border-radius: 50%;
}

.margin-right {
  margin-right: 6px;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.margin-right-5 {
  margin-right: 5px;
}

.standard-spinner-wrapper {
  padding: 40px 0 0 0;
  text-align: center;
}

.standard-spinner-wrapper-floating {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 20px;
  z-index: 1;
  padding: 40px 0 0 0;
  text-align: center;
}

.spinner-wrapper-no-padding {
  text-align: center;
}

.standard-spinner-wrapper.white {
  color: #fff;
}

.no-margin {
  margin: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-5 {
  margin-right: 5px;
}